import CookieService from './cookie.service';
const jwt_decode = require("jwt-decode");
import { of } from "rxjs";
import {
  mockAdminToken,
  mockBasicToken,
  urls
} from "../constants/app-constants";

export class AuthService {
  public getJWTToken() {
    const { REACT_APP_ENV } = process.env;
    const jwtToken =
      REACT_APP_ENV === "local"
        ? mockAdminToken
        : CookieService.getByName("jwt");
    return jwtToken;
  }

  public decodeUser(jwtToken: string) {
    return jwt_decode(jwtToken);
  }

  public login() {
    const jwtToken = this.getJWTToken();

    if (!jwtToken) {
      window.location.replace(urls.pingAuthorizer);
    }

    const user = this.decodeUser(jwtToken!);
    localStorage.setItem("auth", JSON.stringify(user));
    return of(user);
  }
  public logout() {
    CookieService.removeByName("jwt");
    localStorage.removeItem("auth");
    window.location.replace(urls.pingAuthorizer);
  }

  public get isLoggedIn() {
    const user = this.getUserFromLocalStorage();
    if (!user) {
      this.login();
    }

    return user !== null;
  }

  public getUserFromLocalStorage() {
    const userStr = localStorage.getItem("auth");
    return userStr ? JSON.parse(userStr) : null;
  }
}

const defaultOpsService = new AuthService();

export default defaultOpsService;