import { ActionType, createAsyncAction } from 'typesafe-actions';
import { getType } from "typesafe-actions";
import { OpsBatchErrorSummary } from '../../../models/OpsBatchErrorSummary';

const FETCH_ERROR_SUMMARY = 'ERROR_SUMMARY/FETCH';
const FETCH_ERROR_SUMMARY_SUCCESS = 'ERROR_SUMMARY/FETCH_ERROR_SUMMARY_SUCCESS';
const FETCH_ERROR_SUMMARY_FAILURE = 'ERROR_SUMMARY/FETCH_ERROR_SUMMARY_FAILURE';

export const fetchErrorSummary = createAsyncAction(
  FETCH_ERROR_SUMMARY,
  FETCH_ERROR_SUMMARY_SUCCESS,
  FETCH_ERROR_SUMMARY_FAILURE
)<string, {[key:string]: OpsBatchErrorSummary[]}, Error>();

export const fetchErrorSummaryRequest = getType(fetchErrorSummary.request);
export const fetchErrorSummarySuccess = getType(fetchErrorSummary.success);
export const fetchErrorSummaryFailure = getType(fetchErrorSummary.failure);

const FETCH_ERROR_SUMMARIES = 'ERROR_SUMMARIES/FETCH';
const FETCH_ERROR_SUMMARIES_SUCCESS = 'ERROR_SUMMARIES/FETCH_ERROR_SUMMARIES_SUCCESS';
const FETCH_ERROR_SUMMARIES_FAILURE = 'ERROR_SUMMARIES/FETCH_ERROR_SUMMARIES_FAILURE';

export const fetchErrorSummaries = createAsyncAction(
  FETCH_ERROR_SUMMARIES,
  FETCH_ERROR_SUMMARIES_SUCCESS,
  FETCH_ERROR_SUMMARIES_FAILURE
)<string[], {[key:string]: OpsBatchErrorSummary[]}, Error>();

export const fetchErrorSummariesRequest = getType(fetchErrorSummaries.request);
export const fetchErrorSummariesSuccess = getType(fetchErrorSummaries.success);
export const fetchErrorSummariesFailure = getType(fetchErrorSummaries.failure);

export type errorSummaryActionTypes =  ActionType<typeof fetchErrorSummary> | ActionType<typeof fetchErrorSummaries>; 