import CssBaseline from "@material-ui/core/CssBaseline";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { mainTheme } from "bb8/lib/index";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import "react-dates/initialize";
import { connect } from "react-redux";
import { Switch } from "react-router";
import { NavLink } from "react-router-dom";
import ConnectedModal from "./components/ConnectedModal";
import ConnectedNavBar from "./components/ConnectedNavBar";
import ConnectedTopNav from "./components/ConnectedTopNav";
import { IUserState } from "./features/auth/types";
import "./index.scss";
import Routes from "./Routes";
import { history } from "./setupRedux";
import { IRootState, withAuthStateToProps } from "./store";

const App: React.SFC<IUserState> = ({ isLoggedIn }) => (
  <ConnectedRouter history={history}>
    <MuiThemeProvider theme={mainTheme}>
      <CssBaseline />
      <ConnectedTopNav showAuth={true}>
        <NavLink to="/">Ops Portal</NavLink>
      </ConnectedTopNav>
      <ConnectedTopNav subHeader={true} showAuth={false}>
        <NavLink to="/" exact={true}>
          Dashboard
        </NavLink>
        <NavLink to="/sequence" exact={true}>
          Sequence
        </NavLink>
      </ConnectedTopNav>
      {isLoggedIn && <ConnectedNavBar />}
      <Switch children={Routes} />
      <ConnectedModal />
    </MuiThemeProvider>
  </ConnectedRouter>
);

const mapStateToProps = (state: IRootState) => withAuthStateToProps(state);

export default connect(mapStateToProps)(App);
