import cookies from "browser-cookies";

export interface ICookieOptions {
  expires?: number | Date | string;
  domain?: string;
  path?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: string;
}

export class CookieService {
  public createByName(name: string, item: any, options: ICookieOptions) {
    cookies.set(name, item, options);
  }
  public getByName(name: string) {
    return cookies.get(name);
  }
  public getAll() {
    return cookies.all();
  }
  public removeByName(name: string) {
    cookies.erase(name);
  }
}

const defaultCookieService = new CookieService();

export default defaultCookieService;
