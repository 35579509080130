import { missingSequenceActions } from "../actions";
import { IMissingSequenceState } from "../types";

export const initialState: IMissingSequenceState = {
  missingSequences: { sequences: {} },
};

const missingSequenceReducer = (
  state: IMissingSequenceState = initialState,
  action: missingSequenceActions.missingSequenceActionTypes
): IMissingSequenceState => {
  switch (action.type) {

    case missingSequenceActions.fetchMissingSequenceSuccess: {
      return {
        ...state,
        missingSequences: { sequences: action.payload }
      };
    }
    default:
      return state;
  }
};

export default missingSequenceReducer;
