import {
  ActionType,
  createAsyncAction,
} from "typesafe-actions";
import { getType } from "typesafe-actions";
import { MissingSequence } from "../../../models";

const FETCH_MISSING_SEQUENCE = "MISSING_SEQUENCE/FETCH";
const FETCH_MISSING_SEQUENCE_SUCCESS = "MISSING_SEQUENCE/FETCH_SUCCESS";
const FETCH_MISSING_SEQUENCE_ERROR = "MISSING_SEQUENCE/FETCH_ERROR";
export const fetchMissingSequence = createAsyncAction(
  FETCH_MISSING_SEQUENCE,
  FETCH_MISSING_SEQUENCE_SUCCESS,
  FETCH_MISSING_SEQUENCE_ERROR
)<{} , {} | MissingSequence, Error>();
export const fetchMissingSequenceRequest = getType(fetchMissingSequence.request);
export const fetchMissingSequenceSuccess = getType(fetchMissingSequence.success);
export const fetchMissingSequenceFailure = getType(fetchMissingSequence.failure);

export type missingSequenceActionTypes = ActionType<typeof fetchMissingSequence>;
