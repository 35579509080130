import Grid from "@material-ui/core/Grid";
import { Theme, withStyles } from "@material-ui/core/styles";
import {
  BB8Alert,
  BB8AlertTable,
  BB8Button,
  BB8Link,
  BB8MainPage,
  BB8Spinner,
  BB8SystemType,
  BB8Table
} from "bb8/lib";
import classNames from "classnames";
import { push } from "connected-react-router";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { SmartBadge } from "./components/SmartBadge";
import StatCards from "./components/StatCards";
import { errorDetailCodes, errorSummaryCodes } from "./constants/error-codes";
import "./Dashboard.scss";
import { batchActions } from "./features/batch/actions";
import {
  getBatchAlertsCount,
  getBatchAlertsCountTotal,
  getBatchAlertsWithErrors,
  getBatchCompletedCount,
  getBatchesCount,
  getBatchProcessingCount,
  getTop10,
  getTotalElementsAvailable
} from "./features/batch/selectors";
import { OpsBatchErrorSummary, OpsBatchRequestParams } from "./models";
import { OpsBatch, OpsBatchStatus } from "./models/OpsBatch";
import {
  IRootState,
  IWithCommunicationProps,
  withCommunicationDispatchToProps,
  withCommunicationStateToProps
} from "./store";

const styles = (theme: Theme) => ({
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    width: "100%"
  },
  root: {
    flexGrow: 0
  },
  seeAllButton: {
    height: "48px",
    marginLeft: "auto"
  },
  seeAllButtonLink: {
    color: "#FFF !important",
    textDecoration: "none"
  }
});

const alertHeaders = [
  {
    id: "providerCode",
    title: "Provider"
  },
  {
    id: "sequenceNumber",
    title: "Sequence"
  },
  {
    id: "stage",
    title: "Current Stage"
  },
  {
    id: "status",
    title: "Status"
  },
  {
    id: "lastActivityDate",
    title: "Date"
  },
  {
    id: "action",
    title: ""
  }
];

const filesReceivedTodayHeaders = [
  {
    id: "providerCode",
    title: "Provider"
  },
  {
    id: "sequenceNumber",
    title: "Sequence"
  },
  {
    id: "stage",
    title: "Current Stage"
  },
  {
    id: "status",
    title: "Status"
  },
  {
    id: "lastActivityDate",
    title: "Date"
  },
  {
    id: "action",
    title: ""
  }
];

function errorSummaryOccursAccumulator(data: OpsBatch) {
  return data.errorSummaries
    ? data.errorSummaries.map(e => e.occurs).reduce((a, b) => a + b)
    : "...";
}

function mapErrorSummaryData(errorSummary: OpsBatchErrorSummary) {
  return errorSummary
    ? {
        ...errorSummary,
        messageCode:
          errorSummaryCodes[errorSummary.messageCode] ||
          errorDetailCodes[errorSummary.messageCode] ||
          errorSummary.messageCode
      }
    : undefined;
}

function bb8DataMappingResult(myData: OpsBatch[]) {
  return myData.map(data => ({
    ...data,
    action: <BB8Link href={"/files/" + data.batchId} />,
    alertCount: errorSummaryOccursAccumulator(data),
    error: {
      cols: [],
      data: data.errorSummaries
        ? data.errorSummaries.map(mapErrorSummaryData)
        : undefined
    },
    lastActivityDate:
      moment(data.lastActivityDate).format("MM/DD/YYYY") +
      " (" +
      moment(data.lastActivityDate).fromNow() +
      ")",
    status: <SmartBadge opsBatchStatus={data.status} />
  }));
}

export interface IDashboardProps extends IWithCommunicationProps {
  classes: any;
  batches: OpsBatch[];
  batchAlerts: OpsBatch[];
  batchesOnholdCount: number;
  batchesOnholdCountTotal: number;
  batchesCompletedCount: number;
  batchesProcessingCount: number;
  batchesTotalCount: number;
  batchesCount: number;
  goToFiles: () => void;
  fetchData: (requestParams: OpsBatchRequestParams) => void;
  fetchDataOnHold: (requestParams: OpsBatchRequestParams) => void;
  fetchDataOnProcessing: (requestParams: OpsBatchRequestParams) => void;
  fetchDataOnCompleted: (requestParams: OpsBatchRequestParams) => void;
  type: BB8SystemType;
}

export class Dashboard extends React.Component<IDashboardProps> {
  public componentWillMount() {
    const top10Req = new OpsBatchRequestParams();
    top10Req.loadErrors = true;
    top10Req.minFileObservedDate = moment().startOf("d");
    this.props.fetchData(top10Req);

    const processingBatchesReq = new OpsBatchRequestParams();
    processingBatchesReq.loadErrors = false;
    processingBatchesReq.minFileObservedDate = moment().startOf("d");
    processingBatchesReq.statuses = [OpsBatchStatus.Processing];
    this.props.fetchDataOnProcessing(processingBatchesReq);

    const completedBatchesReq = new OpsBatchRequestParams();
    completedBatchesReq.loadErrors = false;
    completedBatchesReq.minDate = moment().startOf("d");
    completedBatchesReq.statuses = [OpsBatchStatus.Completed];
    this.props.fetchDataOnCompleted(completedBatchesReq);

    const onHoldReq = new OpsBatchRequestParams();
    onHoldReq.loadErrors = true;
    // setting it to 10 because RX-280 comments
    onHoldReq.size = 10;
    onHoldReq.sortDirection = "Desc";
    onHoldReq.sortField = "FileObservedDate";
    onHoldReq.statuses = [OpsBatchStatus.OnHold];
    this.props.fetchDataOnHold(onHoldReq);
  }
  public render() {
    const {
      classes,
      batches,
      batchAlerts,
      batchesOnholdCount,
      batchesOnholdCountTotal,
      batchesCompletedCount,
      batchesProcessingCount,
      batchesCount,
      batchesTotalCount,
      error,
      hideAlert,
      isLoading,
      goToFiles,
      showAlert,
      type
    } = this.props;

    const isAlertsEmpty = !isLoading && !batchAlerts.length;
    // const isBatchesEmpty = !isLoading && !batches.length;
    return (
      <BB8MainPage
        className={classes.root}
        type={type || BB8SystemType.OpsPortal}
      >
        <BB8MainPage.Full>
          <StatCards
            className="stat-cards-wrapper"
            batchSummary={{
              all: batchesTotalCount,
              completed: batchesCompletedCount,
              processing: batchesProcessingCount,
              rejected: batchesOnholdCountTotal
            }}
          />
        </BB8MainPage.Full>
        <BB8MainPage.Padded>
          <Grid container={true} direction="column">
            <h3 id="header-latest-today">Alerts ({batchesOnholdCount})</h3>

            <Grid
              container={true}
              className={classNames("alerts-table", { empty: isAlertsEmpty })}
              direction="column"
            >
              <BB8AlertTable
                id={"table-alerts"}
                cols={alertHeaders}
                data={bb8DataMappingResult(batchAlerts)}
                isEmpty={isAlertsEmpty}
              />
            </Grid>
          </Grid>
          <Grid container={true}>
            <Grid
              container={true}
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <h3>Latest 10 for today</h3>
              <BB8Button
                id="btn-see-all-files"
                variant="contained"
                color="primary"
                className={classes.seeAllButton}
                onClick={goToFiles}
              >
                See all files
              </BB8Button>
            </Grid>
            <Grid container={true} className="files-table">
              <BB8Table
                tableId="table-files-received"
                cols={filesReceivedTodayHeaders}
                data={!isLoading ? bb8DataMappingResult(batches) : []}
                noDataComponent={
                  <p>
                    No files found. Do you want to go to{" "}
                    <Link to="/files">Files Search</Link>?
                  </p>
                }
              />
            </Grid>
          </Grid>
        </BB8MainPage.Padded>

        <BB8Spinner size={200} show={isLoading} />
        <BB8Alert
          message={(error && error.message) || "Something bad happened..."}
          variant="error"
          show={showAlert}
          onClose={hideAlert}
        />
      </BB8MainPage>
    );
  }
}

const mapStateToProps = (state: IRootState) =>
  withCommunicationStateToProps(state, {
    batchAlerts: getBatchAlertsWithErrors(state.batchState),
    batches: getTop10(state.batchState),
    batchesCompletedCount: getBatchCompletedCount(state.batchState),
    batchesCount: getBatchesCount(state.batchState),
    batchesOnholdCount: getBatchAlertsCount(state.batchState),
    batchesOnholdCountTotal: getBatchAlertsCountTotal(state.batchState),
    batchesProcessingCount: getBatchProcessingCount(state.batchState),
    batchesTotalCount: getTotalElementsAvailable(state.batchState)
  });

const mapDispatchToProps = (dispatch: Dispatch) =>
  withCommunicationDispatchToProps(dispatch, {
    fetchData: (requestParams: OpsBatchRequestParams) =>
      dispatch(batchActions.fetchBatches.request(requestParams)),
    fetchDataOnCompleted: (requestParams: OpsBatchRequestParams) =>
      dispatch(batchActions.fetchBatchesOnCompleted.request(requestParams)),
    fetchDataOnHold: (requestParams: OpsBatchRequestParams) =>
      dispatch(batchActions.fetchBatchesOnHold.request(requestParams)),
    fetchDataOnProcessing: (requestParams: OpsBatchRequestParams) =>
      dispatch(batchActions.fetchBatchesOnProcessing.request(requestParams)),
    goToFiles: () => dispatch(push("/files"))
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles as any)(Dashboard));
