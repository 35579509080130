import { Observable } from "rxjs";
import { from } from "rxjs";
import { flatMap } from 'rxjs/operators';
import { urls } from "../constants/app-constants";
import { MissingSequence } from "../models";
import apiService from "./api.service";

function mapHttpResponse(response: Response): Observable<MissingSequence | {} | Error> {
  if (!response.ok) {
    return from(
      response
        .json()
        .then(err => {
          throw err;
        })
        .catch(err => {
          throw err;
        })
    );
  } else {
    return from(response.json());
  }
}

export class MissingSequenceService {
  private apiService: any | GlobalFetch;
  constructor(apiServiceDep?: any) {
    this.apiService = apiServiceDep || apiService;
  }

  /**
   * Returns an Observable<Promise>.
   *
   * @remarks
   * This method is part of the {@link core-library#Statistics | Statistics subsystem}.
   *
   * @returns Observable<Promise>
   *
   * @beta
   */
  public getMissingSequence() {
    const request: Request = new Request(
      urls.restApi +
      urls.sequenceController.missingSequence,
      this.apiService.getDefaultRequestInit()
    );
    return from(
      this.apiService.ajax(request)
    ).pipe(
      flatMap<any, any>(mapHttpResponse)
    );
  }
}

const defaultMissingSequenceService = new MissingSequenceService();

export default defaultMissingSequenceService;
