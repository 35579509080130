import React from "react";
import "react-dates/initialize";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import * as authActions from "./features/auth/actions";
import "./index.scss";
import registerServiceWorker from "./registerServiceWorker";
import authService from "./services/auth.service";
import configureStore from "./setupRedux";

const store = configureStore();

registerServiceWorker();

const checkAuth = () => {
  if (authService.isLoggedIn) {
    const user = authService.getUserFromLocalStorage();
    if (user) {
      store.dispatch(authActions.fetchLogin.request(user.role === "admin"));
    }
  }
};

const render = (Component: any) => {
  return ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,
    document.getElementById("root")
  );
};

render(App);
checkAuth();

if ((module as any).hot) {
  (module as any).hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
    checkAuth();
  });
}
