import {
  ActionType,
  createAsyncAction,
  createStandardAction
} from "typesafe-actions";
import { getType } from "typesafe-actions";
import { OpsBatch, OpsBatchDetails, OpsBatchHistory } from "../../../models";
import { IModalProps } from "../../../models/Modal";
import { IOpsBatchRequestParams } from "../../../models/OpsBatchRequestParams";

const FETCH_BATCHES = "BATCHES/FETCH";
const FETCH_BATCHES_SUCCESS = "BATCHES/FETCH_SUCCESS";
const FETCH_BATCHES_ERROR = "BATCHES/FETCH_ERROR";
export const fetchBatches = createAsyncAction(
  FETCH_BATCHES,
  FETCH_BATCHES_SUCCESS,
  FETCH_BATCHES_ERROR
)<null | IOpsBatchRequestParams, OpsBatch[], Error>();
export const fetchBatchesRequest = getType(fetchBatches.request);
export const fetchBatchesSuccess = getType(fetchBatches.success);
export const fetchBatchesFailure = getType(fetchBatches.failure);

const FETCH_BATCHES_ON_HOLD = "BATCHES_ON_HOLD/FETCH";
const FETCH_BATCHES_ON_HOLD_SUCCESS = "BATCHES_ON_HOLD/FETCH_SUCCESS";
const FETCH_BATCHES_ON_HOLD_ERROR = "BATCHES_ON_HOLD/FETCH_ERROR";
export const fetchBatchesOnHold = createAsyncAction(
  FETCH_BATCHES_ON_HOLD,
  FETCH_BATCHES_ON_HOLD_SUCCESS,
  FETCH_BATCHES_ON_HOLD_ERROR
)<null | IOpsBatchRequestParams, OpsBatch[], Error>();
export const fetchBatchesOnHoldRequest = getType(fetchBatchesOnHold.request);
export const fetchBatchesOnHoldSuccess = getType(fetchBatchesOnHold.success);
export const fetchBatchesOnHoldFailure = getType(fetchBatchesOnHold.failure);

const FETCH_BATCHES_ON_PROCESSING = "BATCHES_ON_PROCESSING/FETCH";
const FETCH_BATCHES_ON_PROCESSING_SUCCESS =
  "BATCHES_ON_PROCESSING/FETCH_SUCCESS";
const FETCH_BATCHES_ON_PROCESSING_ERROR = "BATCHES_ON_PROCESSING/FETCH_ERROR";
export const fetchBatchesOnProcessing = createAsyncAction(
  FETCH_BATCHES_ON_PROCESSING,
  FETCH_BATCHES_ON_PROCESSING_SUCCESS,
  FETCH_BATCHES_ON_PROCESSING_ERROR
)<null | IOpsBatchRequestParams, OpsBatch[], Error>();
export const fetchBatchesOnProcessingRequest = getType(
  fetchBatchesOnProcessing.request
);
export const fetchBatchesOnProcessingSuccess = getType(
  fetchBatchesOnProcessing.success
);
export const fetchBatchesOnProcessingFailure = getType(
  fetchBatchesOnProcessing.failure
);

const FETCH_BATCHES_ON_COMPLETED = "BATCHES_ON_COMPLETED/FETCH";
const FETCH_BATCHES_ON_COMPLETED_SUCCESS = "BATCHES_ON_COMPLETED/FETCH_SUCCESS";
const FETCH_BATCHES_ON_COMPLETED_ERROR = "BATCHES_ON_COMPLETED/FETCH_ERROR";
export const fetchBatchesOnCompleted = createAsyncAction(
  FETCH_BATCHES_ON_COMPLETED,
  FETCH_BATCHES_ON_COMPLETED_SUCCESS,
  FETCH_BATCHES_ON_COMPLETED_ERROR
)<null | IOpsBatchRequestParams, OpsBatch[], Error>();
export const fetchBatchesOnCompletedRequest = getType(
  fetchBatchesOnCompleted.request
);
export const fetchBatchesOnCompletedSuccess = getType(
  fetchBatchesOnCompleted.success
);
export const fetchBatchesOnCompletedFailure = getType(
  fetchBatchesOnCompleted.failure
);

const FETCH_BATCH = "BATCH/FETCH";
const FETCH_BATCH_SUCCESS = "BATCH/FETCH_SUCCESS";
const FETCH_BATCH_ERROR = "BATCH/FETCH_ERROR";
export const fetchBatch = createAsyncAction(
  FETCH_BATCH,
  FETCH_BATCH_SUCCESS,
  FETCH_BATCH_ERROR
)<string, OpsBatch, Error>();
export const fetchBatchRequest = getType(fetchBatch.request);
export const fetchBatchSuccess = getType(fetchBatch.success);
export const fetchBatchFailure = getType(fetchBatch.failure);

const FETCH_BATCH_DETAILS = "BATCH_DETAILS/FETCH";
const FETCH_BATCH_DETAILS_SUCCESS = "BATCH_DETAILS/FETCH_SUCCESS";
const FETCH_BATCH_DETAILS_ERROR = "BATCH_DETAILS/FETCH_ERROR";
export const fetchBatchDetails = createAsyncAction(
  FETCH_BATCH_DETAILS,
  FETCH_BATCH_DETAILS_SUCCESS,
  FETCH_BATCH_DETAILS_ERROR
)<string, { [key: string]: OpsBatchDetails[] }, Error>();
export const fetchBatchDetailsRequest = getType(fetchBatchDetails.request);
export const fetchBatchDetailsSuccess = getType(fetchBatchDetails.success);
export const fetchBatchDetailsFailure = getType(fetchBatchDetails.failure);

const FETCH_BATCH_HISTORY = "BATCH_HISTORY/FETCH";
const FETCH_BATCH_HISTORY_SUCCESS = "BATCH_HISTORY/FETCH_SUCCESS";
const FETCH_BATCH_HISTORY_ERROR = "BATCH_HISTORY/FETCH_ERROR";
export const fetchBatchHistory = createAsyncAction(
  FETCH_BATCH_HISTORY,
  FETCH_BATCH_HISTORY_SUCCESS,
  FETCH_BATCH_HISTORY_ERROR
)<string, { [key: string]: OpsBatchHistory[] }, Error>();
export const fetchBatchHistoryRequest = getType(fetchBatchHistory.request);
export const fetchBatchHistorySuccess = getType(fetchBatchHistory.success);
export const fetchBatchHistoryFailure = getType(fetchBatchHistory.failure);

export const clearErrors = createStandardAction("BATCHES/CLEAR_ERRORS")();

export const clearErrorsActionType = getType(clearErrors);

export const dismissModal = createStandardAction("BATCHES/DISMISS_MODAL")();

export const dismissModalActionType = getType(dismissModal);

const REJECT_FILE = "REJECT_FILE/FETCH";
const REJECT_FILE_SUCCESS = "REJECT_FILE/FETCH_SUCCESS";
const REJECT_FILE_ERROR = "REJECT_FILE/FETCH_ERROR";
export const rejectFile = createAsyncAction(
  REJECT_FILE,
  REJECT_FILE_SUCCESS,
  REJECT_FILE_ERROR
)<string, any, Error>();
export const rejectFileRequest = getType(rejectFile.request);
export const rejectFileSuccess = getType(rejectFile.success);
export const rejectFileFailure = getType(rejectFile.failure);

const REPROCESS_FILE = "REPROCESS_FILE/FETCH";
const REPROCESS_FILE_SUCCESS = "REPROCESS_FILE/FETCH_SUCCESS";
const REPROCESS_FILE_ERROR = "REPROCESS_FILE/FETCH_ERROR";
export const reprocessFile = createAsyncAction(
  REPROCESS_FILE,
  REPROCESS_FILE_SUCCESS,
  REPROCESS_FILE_ERROR
)<string, any, Error>();
export const reprocessFileRequest = getType(reprocessFile.request);
export const reprocessFileSuccess = getType(reprocessFile.success);
export const reprocessFileFailure = getType(reprocessFile.failure);

const RETRY_FILE = "RETRY_FILE/FETCH";
const RETRY_FILE_SUCCESS = "RETRY_FILE/FETCH_SUCCESS";
const RETRY_FILE_ERROR = "RETRY_FILE/FETCH_ERROR";
export const retryFile = createAsyncAction(
  RETRY_FILE,
  RETRY_FILE_SUCCESS,
  RETRY_FILE_ERROR
)<string, any, Error>();
export const retryFileRequest = getType(retryFile.request);
export const retryFileSuccess = getType(retryFile.success);
export const retryFileFailure = getType(retryFile.failure);

const AUDIT_FILE = "AUDIT_FILE/FETCH";
const AUDIT_FILE_SUCCESS = "AUDIT_FILE/FETCH_SUCCESS";
const AUDIT_FILE_ERROR = "AUDIT_FILE/FETCH_ERROR";
export const auditFile = createAsyncAction(
  AUDIT_FILE,
  AUDIT_FILE_SUCCESS,
  AUDIT_FILE_ERROR
)<string, any, Error>();
export const auditFileRequest = getType(auditFile.request);
export const auditFileSuccess = getType(auditFile.success);
export const auditFileFailure = getType(auditFile.failure);

const BACKED_OUT_FILE = "BACKED_OUT_FILE/FETCH";
const BACKED_OUT_FILE_SUCCESS = "BACKED_OUT_FILE/FETCH_SUCCESS";
const BACKED_OUT_FILE_ERROR = "BACKED_OUT_FILE/FETCH_ERROR";
export const backedOutFile = createAsyncAction(
  BACKED_OUT_FILE,
  BACKED_OUT_FILE_SUCCESS,
  BACKED_OUT_FILE_ERROR
)<string, any, Error>();
export const backedOutFileRequest = getType(backedOutFile.request);
export const backedOutFileSuccess = getType(backedOutFile.success);
export const backedOutFileFailure = getType(backedOutFile.failure);

export type batchActionTypes =
  | ActionType<typeof fetchBatch>
  | ActionType<typeof fetchBatches>
  | ActionType<typeof fetchBatchesOnHold>
  | ActionType<typeof fetchBatchesOnHold>
  | ActionType<typeof fetchBatchesOnProcessing>
  | ActionType<typeof fetchBatchesOnCompleted>
  | ActionType<typeof fetchBatchDetails>
  | ActionType<typeof fetchBatchHistory>
  | ActionType<typeof rejectFile>
  | ActionType<typeof reprocessFile>
  | ActionType<typeof retryFile>
  | ActionType<typeof auditFile>
  | ActionType<typeof backedOutFile>
  | ActionType<typeof clearErrors>
  | ActionType<typeof dismissModal>;
