import { RouterState } from "connected-react-router";
import { combineReducers, Dispatch } from "redux";
import { combineEpics } from "redux-observable";
import * as loginActions from "../features/auth/actions";
import { fetchLoginEpic, fetchLogoutEpic } from "../features/auth/epics";
import loginReducer from "../features/auth/reducer";
import { IUserState } from "../features/auth/types";
import { batchActions } from "../features/batch/actions";
import {
  auditFileEpic,
  backedOutFileEpic,
  fetchBatchDetailsEpic,
  fetchBatchEpic,
  fetchBatchesEpic,
  fetchBatchesOnCompletedEpic,
  fetchBatchesOnHoldEpic,
  fetchBatchesOnProcessingEpic,
  fetchBatchHistoryEpic,
  fetchErrorSummaryEpic,
  fetchMultipleErrorSummariesEpic,
  rejectFileEpic,
  reprocessFileEpic,
  retryFileEpic
} from "../features/batch/epics";
import { batchesReducer } from "../features/batch/reducers";
import { IBatchState } from "../features/batch/types";
import * as communicationActions from "../features/communication/actions";
import communicationReducer from "../features/communication/reducers";
import { missingSequenceReducer } from "../features/missingSequence/reducers";
import { ICommunicationState } from "../models/Communication";
import { IModalProps } from "../models/Modal";
import { IUser } from "../models/User";
import authService from "../services/auth.service";
import { fetchMissingSequenceEpic } from "./../features/missingSequence/epics/missingSequence";
import { IMissingSequenceState } from "./../features/missingSequence/types";
export interface IRootState {
  auth: IUserState;
  batchState: IBatchState;
  router?: RouterState;
  communication: ICommunicationState;
  missingSequences: IMissingSequenceState;
}

export const rootEpic = combineEpics(
  fetchBatchesEpic,
  fetchBatchesOnHoldEpic,
  fetchBatchesOnCompletedEpic,
  fetchBatchesOnProcessingEpic,
  fetchBatchDetailsEpic,
  fetchBatchEpic,
  fetchBatchHistoryEpic,
  fetchErrorSummaryEpic,
  fetchMultipleErrorSummariesEpic,
  fetchLoginEpic,
  fetchLogoutEpic,
  auditFileEpic,
  retryFileEpic,
  reprocessFileEpic,
  rejectFileEpic,
  fetchMissingSequenceEpic,
  backedOutFileEpic
);

const rootReducer = combineReducers<IRootState>({
  auth: loginReducer,
  batchState: batchesReducer,
  communication: communicationReducer,
  missingSequences: missingSequenceReducer
});

export interface IWithCommunicationProps {
  isLoading: boolean;
  showModal?: IModalProps;
  showAlert: boolean;
  error?: Error;
  confirm: (props: IModalProps) => void;
  hideAlert: () => void;
  hideModal: () => void;
}

export function withAuthStateToProps<T = object>(
  state: IRootState,
  maps?: T
): IUserState & T {
  return {
    isLoggedIn: authService.isLoggedIn,
    user: state.auth.user,
    ...(maps as any)
  };
}

interface IWithAuthDispatchToProps {
  login: () => IUser;
  logout: () => void;
}
export function withAuthDispatchToProps<T = object>(
  dispatch: Dispatch,
  maps?: T
): IWithAuthDispatchToProps & T {
  return {
    login: () => dispatch(loginActions.fetchLogin.request(false)),
    logout: () => dispatch(loginActions.fetchLogout.request()),
    ...(maps as any)
  };
}

export function withCommunicationStateToProps<T = object>(
  state: IRootState,
  maps?: T
): IWithCommunicationProps & T {
  return {
    isLoading: state.communication.pendingRequests > 0,
    showAlert:
      state.communication.pendingRequests === 0 && !!state.communication.error,
    showModal: state.communication.showModal,
    ...(maps as any)
  };
}
export function withCommunicationDispatchToProps(
  dispatch: Dispatch,
  maps?: any
) {
  return {
    confirm: (modalProps: IModalProps) =>
      dispatch(communicationActions.displayModal(modalProps)),
    hideAlert: () => dispatch(communicationActions.clearErrors()),
    hideModal: () => dispatch(communicationActions.dismissModal()),
    ...maps
  };
}

export default rootReducer;
