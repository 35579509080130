import { Moment } from "moment";

export class OpsBatchHistory {
  public activityType: string;
  public date: string | Date | Moment;
  public stage: string;
  public status: string;
  public batchId: string;
  constructor(
    activityType: string,
    date: string | Date | Moment,
    stage: string,
    status: string,
    batchId: string
  ) {
    this.activityType = activityType;
    this.date = date;
    this.stage = stage;
    this.status = status;
    this.batchId = batchId;
  }
}
