import { GridList, GridListTile } from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { WithStylesOptions } from "@material-ui/core/styles/withStyles";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { objectToQueryString } from "../helpers";
import "./StatCards.scss";

const styles = (theme: Theme) =>
  createStyles({
    gridList: {
      flexWrap: "nowrap",
      paddingTop: "28px",
      transform: "translateZ(0)",
      width: "1366px"
    },
    gridTile: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "244px"
    }
  });

export interface IStatCardProps extends WithStylesOptions {
  batchSummary: {
    all: number;
    rejected: number;
    processing: number;
    completed: number;
  };
  className: string;
  classes: any;
}

export const StatCards = ({ batchSummary, classes }: IStatCardProps) => {
  const data = [
    {
      filters: { date: "today" },
      title: "Files received today",
      value: batchSummary.all
    },
    {
      filters: {
        statuses: "Hold",
        sortDirection: "Desc",
        sortField: "FileObservedDate"
      },
      title: "File Alerts",
      value: batchSummary.rejected
    },
    {
      filters: { statuses: "InProgress", date: "today" },
      title: "Queued for processing",
      value: batchSummary.processing
    },
    {
      filters: {
        statuses: "Complete",
        date: "today",
        minDate: moment().startOf("day"),
        minFileObservedDate: undefined
      },
      title: "Completed today",
      value: batchSummary.completed
    }
  ];
  return (
    <section className="stats-cards-wrapper">
      <GridList
        cellHeight={120}
        cols={4}
        className={"stats-cards " + classes.gridList}
      >
        {data.map(d => (
          <GridListTile
            id={d.title}
            key={d.title}
            className={"stat-card " + classes.gridTile}
          >
            <Link
              to={{
                pathname: "files",
                search: "?" + objectToQueryString(d.filters)
              }}
            >
              <h3>{d.title}</h3>
              <p>{d.value}</p>
            </Link>
          </GridListTile>
        ))}
      </GridList>
    </section>
  );
};

export default withStyles(styles)(StatCards);
