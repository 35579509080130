// tslint:disable:object-literal-sort-keys
export const errorSummaryCodes: any = {
  ExpiredProviderCode: "Expired Provider Code",
  InvalidHeaderType: "Blank/invalid header id",
  InvalidProviderCode: "Blank/Invalid provider code",
  InvalidTotalNumberOfMiles: "Blank/invalid miles issued",
  IssuerProviderRelationshipNotFound: "Sponsor/Provider Relationship does not exist or is expired",
  InvalidTransmissionSequenceNumber: "Invalid sequence number",
  InvalidProcessDate: "Blank/Invalid process date",
  InvalidProcessTime: "Blanks/Invalid time format",
  InvalidTotalNumberOfRecords: "Invalid # of records",
  NotMatchedRecordCount:"Number of records in header doesn't match number of records in file",
  NotMatchedMilesIssued:"Number of miles in header does not match number of miles in detailed records",
  ProviderSponsorRelationship:"Sponsor/Provider Relationship does not exist or is expired",
  ProviderCannotSendIssuance: "Invalid file type, provider is not set up to send specific file type",
  ProviderStartDateIsFutureDate: "Provider start date is future date",
  SuspectedDuplicate: "Suspected Duplicate",
};

export const errorDetailCodes: any = {
  AccountNumberInvalid:"Collector Non-Numeric",
  CollectorClosed:"Closed Account",
  CollectorDeleted:"Invalid Collector Number, DELETED",
  CollectorNotEmployee:"Collector is not an employee",
  CollectorNotFound:"Invalid Collector Number, NOT FOUND",
  DetailHeaderTypeInvalid:"Blank/invalid detail record type" ,
  ExpiredOfferCode:"Expired Offer Code",
  ExpiredProviderCode:"Expired Provider Code",
  InvalidHeaderType:"Blank/invalid header id",
  InvalidProviderCode:"Blank/Invalid provider code",
  ProviderStartDateIsFutureDate:"Provider start date is future date",
  ProviderCannotSendIssuance:"Invalid file type, provider is not set up to send specific file type",
  ProviderSponsorRelationship:"Sponsor/Provider Relationship does not exist or is expired",
  IssuerProviderRelationshipNotFound:"Sponsor/Provider Relationship does not exist or is expired",
  InvalidTransmissionSequenceNumber:"Invalid sequence number",
  InvalidProcessDate:"Blank/Invalid process date",
  InvalidProcessTime:"Blanks/Invalid time format",
  InvalidTotalNumberOfRecords: "Invalid # of records",
  IssuerCodeInvalid:"Blank/invalid issuer code",
  IssuerNotFound:"Issuer Code Does Not Exist",
  IssuerExpired:"Expired Issuer Code",
  InvalidOfferCode:"Blank/invalid offer code",
  OfferNotFound:"Offer code Does Not Exist for Sponsor Code Used",
  LocationCodeInvalid:"Location Does Not Exist for Sponsor Code Used",
  LocationNotFound:"Location Does Not Exist for Sponsor Code Used",
  InactiveLocationCode:"Inactive Location Code",
  LocationCodeNotProvided:"Missing required location",
  MilesIssuedInvalid:"Miles Not Numeric",
  MileageDiscrepancy:"Mileage Discrepancy",
  UnitMileageMismatch:"Miles do not match Unit Mileage allowed",
  VariableMileageAllowedExceeded:"Miles exceed Variable Mileage amount allowed",
  SuspectedDuplicate:"Suspected Duplicate",
};

