import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import authService from "../../services/auth.service";
import * as loginActions from "./actions";

export const fetchLoginEpic = (action: any) =>
  action.pipe(
    filter(isActionOf(loginActions.fetchLogin.request)),
    switchMap(() =>
      authService.login().pipe(
        map(loginActions.fetchLogin.success),
        catchError(err => of(loginActions.fetchLogin.failure(err)))
      )
    )
  );

export const fetchLogoutEpic = (action: any) =>
  action.pipe(
    filter(isActionOf(loginActions.fetchLogout.request)),
    map(() => authService.logout())
  );
