import { Observable, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { MissingSequence, } from "../../../models";
import MissingSequenceService from "../../../services/missingSequence.service";
import {
  missingSequenceActions,
} from "../actions";

export function mapResponseToMissingSequence(data: any): MissingSequence {
  return new MissingSequence(
    data,
  );
}

export const fetchMissingSequenceEpic = (
  action: Observable<missingSequenceActions.missingSequenceActionTypes>
) =>
  action.pipe(
    filter(isActionOf(missingSequenceActions.fetchMissingSequence.request)),
    switchMap(() => {
      return MissingSequenceService.getMissingSequence().pipe(
        map(data => mapResponseToMissingSequence(data)),
        map(missingSequenceActions.fetchMissingSequence.success),
        catchError(err => of(missingSequenceActions.fetchMissingSequence.failure(err)))
      );
    })
  );
