import moment from "moment";
import { createSelector } from "reselect";
import { OpsBatch, OpsBatchDetails, OpsBatchStatus } from "../../../models";
import { OpsBatchErrorSummary } from "../../../models";
import { OpsBatchHistory } from "../../../models/OpsBatchHistory";
import { IBatchState } from "../types";
import { IRootState } from "./../../../store/index";
import { getErrorSummaries } from "./errorSummary";

function sortByDate(b1: OpsBatch, b2: OpsBatch): number {
  const d1 = moment(b1.lastActivityDate);
  const d2 = moment(b2.lastActivityDate);
  return d1.isBefore(d2) ? 1 : d2.isBefore(d1) ? -1 : 0;
}

export const getBatches = (state: IBatchState) =>
  state.batches.sort(sortByDate);

export const getBatchesOnHold = (state: IBatchState) =>
  state.batchesOnHold.sort(sortByDate);
export const getBatchesOnCompleted = (state: IBatchState) =>
  state.batchesOnCompleted.sort(sortByDate);
export const getBatchesOnProcessing = (state: IBatchState) =>
  state.batchesOnProcessing.sort(sortByDate);

export const getBatchDetailsMap = (state: IBatchState) => state.batchDetailsMap;
export const getBatchHistoryMap = (state: IBatchState) => state.batchHistoryMap;
export const getBatchesForFileList = (state: IRootState) =>
  state.batchState.batches.sort(sortByDate);

export const getBatchesCount = createSelector(
  getBatches,
  batches => (batches ? batches.length : 0)
);

export const getTotalElementsAvailable = createSelector(
  getBatches,
  batches => (batches && batches[0] ? batches[0].totalElementsAvailable : 0)
);

export const getBatchesFilter = (state: IBatchState) => state.batchesFilter;

export const getTop10 = createSelector(
  getBatches,
  batches => batches.slice(0, 10)
);

export const getBatchAlerts = createSelector(
  getBatchesOnHold,
  batches => getFilteredByStatus(OpsBatchStatus.OnHold, batches)
);

export const getBatchWithDetails = createSelector(
  getBatches,
  getErrorSummaries,
  getBatchDetailsMap,
  getBatchHistoryMap,
  (
    batches: OpsBatch[],
    errorSummaries?: { [key: string]: OpsBatchErrorSummary[] },
    batchDetailsMap?: { [key: string]: OpsBatchDetails[] },
    batchHistoryMap?: { [key: string]: OpsBatchHistory[] }
  ) => {
    return batches.map(batch => {
      if (errorSummaries) {
        batch.errorSummaries = errorSummaries[batch.batchId];
      }
      if (batchDetailsMap) {
        batch.details = batchDetailsMap[batch.batchId];
      }
      if (batchHistoryMap) {
        batch.history = batchHistoryMap[batch.batchId];
      }
      return batch;
    });
  }
);

export const getBatchAlertsWithErrors = createSelector(
  getBatchAlerts,
  getErrorSummaries,
  getBatchDetailsMap,
  getBatchHistoryMap,
  (
    batches: OpsBatch[],
    errorSummaries?: { [key: string]: OpsBatchErrorSummary[] },
    batchDetailsMap?: { [key: string]: OpsBatchDetails[] },
    batchHistoryMap?: { [key: string]: OpsBatchHistory[] }
  ) => {
    return batches.map(batch => {
      if (errorSummaries) {
        batch.errorSummaries = errorSummaries[batch.batchId];
      }
      if (batchDetailsMap) {
        batch.details = batchDetailsMap[batch.batchId];
      }
      if (batchHistoryMap) {
        batch.history = batchHistoryMap[batch.batchId];
      }
      return batch;
    });
  }
);

export const getBatchAlertsCount = createSelector(
  getBatchesOnHold,
  alerts => (alerts ? alerts.length : 0)
);

export const getBatchAlertsCountTotal = createSelector(
  getBatchesOnHold,
  alerts => (alerts && alerts[0] ? alerts[0].totalElementsAvailable : 0)
);

export const getCompleted = createSelector(
  getBatchesOnCompleted,
  batches => getFilteredByStatus(OpsBatchStatus.Completed, batches)
);

export const getBatchCompletedCount = createSelector(
  getBatchesOnCompleted,
  batches => (batches && batches[0] ? batches[0].totalElementsAvailable : 0)
);

export const getProcessing = createSelector(
  getBatches,
  batches => getFilteredByStatus(OpsBatchStatus.Processing, batches)
);

export const getBatchProcessingCount = createSelector(
  getBatchesOnProcessing,
  batches => (batches && batches[0] ? batches[0].totalElementsAvailable : 0)
);

export const getFilteredBatches = createSelector(
  getBatches,
  getBatchesFilter,
  (batches, filter) => {
    getFilteredByStatus(filter.status, batches);
  }
);

export function getFilteredByStatus(
  status: OpsBatchStatus,
  batches: OpsBatch[]
) {
  switch (status) {
    case OpsBatchStatus.Completed:
      return batches.filter(t => t.status === OpsBatchStatus.Completed);
    case OpsBatchStatus.Processing:
      return batches.filter(t => t.status === OpsBatchStatus.Processing);
    case OpsBatchStatus.OnHold:
      return batches.filter(t => t.status === OpsBatchStatus.OnHold);
    case OpsBatchStatus.Rejected:
      return batches.filter(t => t.status === OpsBatchStatus.Rejected);
    default:
      return batches;
  }
}
