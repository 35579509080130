import React from "react";
import { Route } from "react-router";
import asyncComponent from "./AsyncComponent";
import ConnectedPrivateRoute from "./components/ConnectedProtectedRoute";
import Dashboard from "./Dashboard";

const AsyncFileList = asyncComponent(() =>
  import("./features/batch/components/FileList")
);
const AsyncFileDetails = asyncComponent(() =>
  import("./features/batch/components/FileDetails")
);
const AsyncSequenceNumbers = asyncComponent(() =>
  import("./features/missingSequence/components/MissingSequenceNumbers")
);
const AsyncUnauthorized = asyncComponent(() => import("./Unauthorized"));
const AsyncNotFound = asyncComponent(() => import("./NotFound"));

const Routes: JSX.Element[] = [
  <ConnectedPrivateRoute
    key={1}
    path="/files/:id"
    component={AsyncFileDetails}
  />,
  <ConnectedPrivateRoute key={2} path="/files" component={AsyncFileList} />,
  <ConnectedPrivateRoute
    key={3}
    path="/sequence"
    component={AsyncSequenceNumbers}
  />,
  <ConnectedPrivateRoute key={4} exact={true} path="/" component={Dashboard} />,
  <Route key={2} component={AsyncUnauthorized} path="/auth" />,
  <Route key={5} component={AsyncNotFound} />
];

export default Routes;
