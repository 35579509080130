import AuthService from "./auth.service";

export class ApiService {
  // tslint:disable-next-line:ban-types
  private api?: Function;

  // tslint:disable-next-line:no-unnecessary-initializer
  constructor(mockFetch?: () => Promise<any>) {
    if (mockFetch) {
      this.api = mockFetch;
    }
  }

  public ajax(request: string | Request) {
    if (this.api) {
      return this.api(request);
    }

    return fetch(request);
  }

  // private buildRequest(url: string) {
  //   const request = new Request(url);
  //   // request.headers.set("Authorization", "Bearer " + tempToken);
  //   return request;
  // }
  protected getDefaultRequestInit(method = "GET") {
    const user = JSON.parse(localStorage.getItem("auth") as any);
    const init: RequestInit = {
      cache: "default",
      headers: {
        Authorization: AuthService.getJWTToken()!,
        "Content-Type": "application/json",
        "X-User-FullName": (user && user.username) || ""
      },
      method,
      mode: "cors"
    };
    return init;
  }
}

const apiService = new ApiService();

export default apiService;
