export class OpsBatchErrorSummary {
  public messageCode: string;
  public messageType: string;
  public messageSeverity: string;
  public occurs: number;
  public batchId: string;
  public breakdown: IOpsBatchErrorSummaryBreakdown;
  constructor(
    messageCode: string,
    messageSeverity: string,
    messageType: string,
    occurs: number,
    breakdown: IOpsBatchErrorSummaryBreakdown,
    batchId: string
  ) {
    this.messageCode = messageCode;
    this.messageType = messageType;
    this.messageSeverity = messageSeverity;
    this.occurs = occurs;
    this.breakdown = breakdown;
    this.batchId = batchId;
  }
}

export interface IOpsBatchErrorSummaryBreakdown {
  headers: any[];
  records: any[];
}
