import { ActionType, createAsyncAction } from "typesafe-actions";
import { IUser } from "../../models/User";
const FETCH_LOGIN = "LOGIN/FETCH";
const FETCH_LOGIN_SUCCESS = "LOGIN/FETCH_SUCCESS";
const FETCH_LOGIN_ERROR = "LOGIN/FETCH_ERROR";

export const fetchLogin = createAsyncAction(
  FETCH_LOGIN,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_ERROR
)<boolean | undefined, IUser, Error>();

const FETCH_LOGOUT = "LOGOUT/FETCH";
const FETCH_LOGOUT_SUCCESS = "LOGOUT/FETCH_SUCCESS";
const FETCH_LOGOUT_ERROR = "LOGOUT/FETCH_ERROR";

export const fetchLogout = createAsyncAction(
  FETCH_LOGOUT,
  FETCH_LOGOUT_SUCCESS,
  FETCH_LOGOUT_ERROR
)<void, void, Error>();

export type loginActionTypes = ActionType<
  typeof fetchLogin | typeof fetchLogout
>;
