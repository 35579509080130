import { forkJoin, Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { ActionType, isActionOf } from "typesafe-actions";
import { OpsBatchErrorSummary } from "../../../models";
import opsService from "../../../services/ops.service";
import { errorSummaryActions } from "../actions";
import {
  fetchErrorSummaries,
  fetchErrorSummary
} from "../actions/errorSummary";

export function mapDataToOpsBatchErrorSummary(data: any): OpsBatchErrorSummary {
  return new OpsBatchErrorSummary(
    data.messageCode,
    data.messageSeverity,
    data.messageType,
    data.occurs,
    data.breakdown,
    data.batchId
  );
}

export const fetchErrorSummaryEpic = (
  action: Observable<ActionType<typeof fetchErrorSummary>>
) =>
  action.pipe(
    filter(isActionOf(errorSummaryActions.fetchErrorSummary.request)),
    switchMap(({ payload }) =>
      opsService.errorSummary(payload).pipe(
        map((data: any[]) => data.map((d: any) => mapDataToOpsBatchErrorSummary(d))),
        map(errorSummary => mapErrors([errorSummary])),
        map(errorSummaryActions.fetchErrorSummary.success),
        catchError(err =>
          of(errorSummaryActions.fetchErrorSummary.failure(err))
        )
      )
    )
  );

export const fetchMultipleErrorSummariesEpic = (
  action: Observable<ActionType<typeof fetchErrorSummaries>>
) =>
  action.pipe(
    filter(isActionOf(errorSummaryActions.fetchErrorSummaries.request)),
    mergeMap(({ payload }) =>
      forkJoin(...payload.map(batchId => opsService.errorSummary(batchId)))
    ),
    map(responses => mapErrors(responses)),
    map(errorSummaryActions.fetchErrorSummaries.success),
    catchError(err => of(errorSummaryActions.fetchErrorSummaries.failure(err)))
  );

function mapErrors(
  responses: any[]
): { [key: string]: OpsBatchErrorSummary[] } {
  const result: any = {};
  responses.forEach(errors => {
    if (errors && errors.length) {
      const id = errors[0].batchId;
      result[id] = errors.map((d: any) => mapDataToOpsBatchErrorSummary(d));
    }
  });

  return result;
}
