import { BB8TopNav } from "bb8/lib/index";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IRootState, withAuthDispatchToProps } from "../store/index";

export const ConnectedTopNav = connect<any, any, any, any>(
  (state: IRootState) => ({ user: state.auth.user }),
  (dispatch: Dispatch) => withAuthDispatchToProps(dispatch),
  undefined,
  { pure: false }
)(BB8TopNav);

export default ConnectedTopNav;
