import { BB8Modal } from "bb8/lib/";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  IRootState,
  IWithCommunicationProps,
  withCommunicationDispatchToProps,
  withCommunicationStateToProps
} from "../store";

const mapStateToProps = (state: IRootState) =>
  withCommunicationStateToProps(state, {});
const mapDispacthToProps = (dispatch: Dispatch) =>
  withCommunicationDispatchToProps(dispatch, {});

const ConnectedModal: React.SFC<any | IWithCommunicationProps> = ({
  showModal,
  hideModal
}) => {
  if (!showModal) {
    return null;
  }
  return (
    <BB8Modal open={!!showModal} onClose={hideModal} type={showModal.type}>
      <showModal.message />
    </BB8Modal>
  );
};

export default connect(
  mapStateToProps,
  mapDispacthToProps
)(ConnectedModal);
