import {
  OpsBatch,
  OpsBatchErrorSummary,
  OpsBatchStage,
  OpsBatchStatus
} from "../../../models";
import { batchActions, errorSummaryActions } from "../actions";
import { IBatchState } from "../types";

export const initialState: IBatchState = {
  batchDetailsMap: {},
  batchHistoryMap: {},
  batches: [],
  batchesFilter: {
    date: "today",
    stage: OpsBatchStage.HeaderValidation,
    status: OpsBatchStatus.All
  },
  batchesOnCompleted: [],
  batchesOnHold: [],
  batchesOnProcessing: [],
  errorSummaries: undefined
};

const batchesReducer = (
  state: IBatchState = initialState,
  action:
    | batchActions.batchActionTypes
    | errorSummaryActions.errorSummaryActionTypes
): IBatchState => {
  switch (action.type) {
    case batchActions.fetchBatchesSuccess: {
      return {
        ...state,
        batches: action.payload,
        timestamp: new Date().getTime()
      };
    }
    case batchActions.fetchBatchesOnHoldSuccess: {
      return {
        ...state,
        batchesOnHold: action.payload,
        timestamp: new Date().getTime()
      };
    }
    case batchActions.fetchBatchesOnCompletedSuccess: {
      return {
        ...state,
        batchesOnCompleted: action.payload,
        timestamp: new Date().getTime()
      };
    }
    case batchActions.fetchBatchesOnProcessingSuccess: {
      return {
        ...state,
        batchesOnProcessing: action.payload,
        timestamp: new Date().getTime()
      };
    }
    case batchActions.fetchBatchSuccess: {
      return {
        ...state,
        batches: [...state.batches, action.payload]
      };
    }
    case batchActions.fetchBatchDetailsSuccess: {
      return {
        ...state,
        batchDetailsMap: { ...state.batchDetailsMap, ...action.payload }
      };
    }
    case batchActions.fetchBatchHistorySuccess: {
      return {
        ...state,
        batchHistoryMap: { ...state.batchHistoryMap, ...action.payload }
      };
    }
    case errorSummaryActions.fetchErrorSummarySuccess:
    case errorSummaryActions.fetchErrorSummariesSuccess: {
      return {
        ...state,
        errorSummaries: { ...state.errorSummaries, ...action.payload }
      };
    }
    default:
      return state;
  }
};

export default batchesReducer;

export function updateObjectInArray(
  opsBatches: OpsBatch[],
  errorSummaries: OpsBatchErrorSummary[]
) {
  const batchId = errorSummaries[0].batchId;
  return opsBatches.map(item => {
    if (item.batchId !== batchId) {
      // This isn't the item we care about - keep it as-is
      return item;
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      errorSummaries
    };
  });
}
