import React from "react";
export enum BB8ModalTypes {
  Success = 1,
  Warning = 2,
  Error = 3,
  Info = 4
}
export interface IModalProps {
  action: string;
  type?: BB8ModalTypes;
  message: React.SFC;
}
