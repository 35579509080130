export const mockAdminToken = `eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJDU2FsZGFuaGFAbG95YWx0eS5jb20iLCJpYXQiOjE1NDM1MzE3MTYsInN1YiI6IkNTYWxkYW5oYUBsb3lhbHR5LmNvbSIsImlzcyI6ImxveWFsdHlvbmU6c2FtbDI6cHJvZCIsInVzZXJuYW1lIjoiQ2xpdmUgU2FsZGFuaGEiLCJlbWFpbCI6ImNzYWxkYW5oYUBsb3lhbHR5LmNvbSIsInJvbGUiOiJhZG1pbiIsImV4cCI6NDEzMTIxMjQzMn0.hC2nUIQZrQfUoS_c3mhdXDqK6AEHcQOdXhlmXsHJ3Ns`;
export const mockBasicToken = `eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJDU2FsZGFuaGFAbG95YWx0eS5jb20iLCJpYXQiOjE1NDM1MzE3MTYsInN1YiI6IkNTYWxkYW5oYUBsb3lhbHR5LmNvbSIsImlzcyI6ImxveWFsdHlvbmU6c2FtbDI6cHJvZCIsInVzZXJuYW1lIjoiQ2xpdmUgU2FsZGFuaGEiLCJlbWFpbCI6ImNzYWxkYW5oYUBsb3lhbHR5LmNvbSIsInJvbGUiOiJiYXNpYyIsImV4cCI6NDEzMTIxMjQzMn0.gwyl7yeMP2EBI8n6z8-4V9QbENpIuAYcNg5PVXadTnA`;

export const urls = {
  batchControllerForOps: {
    batch: "/batch",
    getBatch(batchId: string) {
      return `/batch/${batchId}`;
    },
    backedOutBatch(batchId: string) {
      return "/batch/" + batchId + "/backout";
    },
    batchDetails(
      batchId: string,
      detailType: "all" | "errorsOnly" | "successesOnly" | "withMessages"
    ) {
      return `/batch/${batchId}/details/${detailType}`;
    },
    batchHistory(batchId: string) {
      return `/batch/${batchId}/history`;
    },
    errorSummary(batchId: string) {
      return "/batch/" + batchId + "/errorSummary?detail=true";
    },
    forceAudit(batchId: string) {
      return "/batch/" + batchId + "/forceAudit";
    },
    rejectBatch(batchId: string) {
      return "/batch/" + batchId + "/reject";
    },
    reprocessBatch(batchId: string) {
      return "/batch/" + batchId + "/reprocess";
    },
    retryBatch(batchId: string) {
      return "/batch/" + batchId + "/retry";
    }
  },
  batchControllerForProcessor: {
    batchInternal: "/batch-internal"
  },
  batchErrorController: {
    error: "/error"
  },
  login: "/login",
  logout: "/logout",
  operationHandler: {
    health: "/health",
    info: "/info"
  },
  pingAuthorizer: "https://desktop.pingone.com/loyalty",
  restApi:
    process.env.REACT_APP_ENV === "local"
      ? ""
      : process.env.REACT_APP_REST_API_LOCATION,
  sequenceController: {
    missingSequence: "/report/missingSequenceNumbers"
  }
};
