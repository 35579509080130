import { BB8NavBar, BB8TextSearch } from "bb8/lib/index";
import { push, replace } from "connected-react-router";
import { Location } from "history";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { batchActions } from "../features/batch/actions";
import { objectToQueryString, queryStringToObject } from "../helpers";
import { OpsBatchRequestParams } from "../models";
import { IRootState } from "../store";

function pathToTitle(pathname: string): string {
  switch (pathname) {
    case "/":
      return "Dashboard";
    case "/files":
      return "File List";
    case "/sequence":
      return "Missing Sequence";
    default:
      // checking if path ends with .../files/:id
      if (/\/files\/\d+$/g.test(pathname)) {
        return "File Details";
      }
      return "";
  }
}

const searchRegex = /^(\w{3,})\s?(\d+)?$/i;

function searchValidation(str: string): boolean {
  return !!str && searchRegex.test(str);
}

function getSearchRegexGroups(str: string) {
  return !!str && str.match(searchRegex);
}

const textSearchValidators = [searchValidation];

function queryParamsToString(search: string): string | undefined {
  if (!search) {
    return undefined;
  }
  const obj = queryStringToObject(decodeURIComponent(search.replace("?", "")));
  return obj.search || `${obj.providerCode || ""} ${obj.sequenceNumber || ""}`;
}

export const LocationAwareNavBar = ({
  location,
  search,
  searchInitialText
}: {
  location: Location;
  search: (text: string) => void;
  searchInitialText?: string;
}) => {
  const title = pathToTitle(location.pathname);
  return (
    <BB8NavBar id={"header-" + title.replace(" ", "-")} title={title}>
      <div style={{ width: "320px" }}>
        <BB8TextSearch
          placeholder="Search for provider code and sequence"
          onSearch={search}
          validators={textSearchValidators}
          initialText={searchInitialText}
        />
      </div>
    </BB8NavBar>
  );
};

const mapStateToProps = (state: IRootState) => ({
  location: state.router!.location,
  searchInitialText: queryParamsToString(location.search.replace("?", ""))
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  search: (text: string) => {
    const [, issuer, seq] = getSearchRegexGroups(text) as RegExpMatchArray;
    const qs = {} as any;
    if (issuer) {
      qs.providerCode = issuer;
    }
    if (seq) {
      qs.sequenceNumber = seq;
    }
    if (location.pathname !== "/files") {
      dispatch(push("/files?" + objectToQueryString(qs), { refresh: true }));
    } else {
      const {
        date,
        maxFileObservedDate,
        minFileObservedDate,
        stages,
        statuses
      } = OpsBatchRequestParams.fromQueryString(location.search);
      const request = new OpsBatchRequestParams({
        date,
        maxFileObservedDate,
        minFileObservedDate,
        providerCode: qs.providerCode,
        sequenceNumber: qs.sequenceNumber,
        stages,
        statuses
      });
      dispatch(replace({ search: "?" + request.toQueryString() }));
      dispatch(
        batchActions.fetchBatches.request(new OpsBatchRequestParams(request))
      );
    }
  }
});

export const ConnectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationAwareNavBar);

export default ConnectedNavBar;
