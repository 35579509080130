import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { Observable, of } from "rxjs";
import { catchError, filter, flatMap, map, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import * as communicationActions from "../../../features/communication/actions";
import { groupBy } from "../../../helpers";
import {
  OpsBatch,
  OpsBatchDetails,
  OpsBatchRequestParams,
  OpsBatchStatus
} from "../../../models";
import { BB8ModalTypes, IModalProps } from "../../../models/Modal";
import { OpsBatchHistory } from "../../../models/OpsBatchHistory";
import opsService from "../../../services/ops.service";
import { displayModal } from "../../communication/actions";
import { batchActions, errorSummaryActions } from "../actions";

export function mapResponseToOpsBatch(
  data: any,
  totalElementsAvailable: number
): OpsBatch {
  return new OpsBatch(
    data.acceptedWithoutWarningsLines,
    data.acceptedWithoutWarningsMiles,
    data.batchId,
    data.lastActivityDate ? moment(data.lastActivityDate) : undefined,
    data.providerCode,
    data.rejectedLines,
    data.rejectedMiles,
    data.sequenceNumber,
    data.stage,
    data.status,
    data.lastActivityUsername,
    data.acceptedWithWarningsLines,
    data.acceptedWithWarningsMiles,
    data.userName,
    data.totalAcceptedLines,
    data.totalAcceptedMiles,
    data.totalLines,
    data.totalMiles,
    totalElementsAvailable
  );
}

function filterByAlertsOnly(batches: OpsBatch[]) {
  return batches.filter(b => b.status === OpsBatchStatus.OnHold);
}

export const fetchBatchesEpic = (
  action: Observable<batchActions.batchActionTypes>
) =>
  action.pipe(
    filter(isActionOf(batchActions.fetchBatches.request)),
    switchMap(({ payload }) => {
      return opsService.listBatches(payload || undefined).pipe(
        map(data =>
          data
            ? (data as any).content.map((content: any) =>
                mapResponseToOpsBatch(content, (data as any).totalElements)
              )
            : []
        ),
        flatMap(response => {
          const obs: any[] = [batchActions.fetchBatches.success(response)];
          return obs;
        }),
        catchError(err => of(batchActions.fetchBatches.failure(err)))
      );
    })
  );

export const fetchBatchesOnHoldEpic = (
  action: Observable<batchActions.batchActionTypes>
) =>
  action.pipe(
    filter(isActionOf(batchActions.fetchBatchesOnHold.request)),
    switchMap(({ payload }) => {
      return opsService.listBatches(payload || undefined).pipe(
        map(data =>
          data
            ? (data as any).content.map((content: any) =>
                mapResponseToOpsBatch(content, (data as any).totalElements)
              )
            : []
        ),
        flatMap(response => {
          const obs: any[] = [
            batchActions.fetchBatchesOnHold.success(response)
          ];
          return obs;
        }),
        catchError(err => of(batchActions.fetchBatchesOnHold.failure(err)))
      );
    })
  );

export const fetchBatchesOnProcessingEpic = (
  action: Observable<batchActions.batchActionTypes>
) =>
  action.pipe(
    filter(isActionOf(batchActions.fetchBatchesOnProcessing.request)),
    switchMap(({ payload }) => {
      return opsService.listBatches(payload || undefined).pipe(
        map(data =>
          data
            ? (data as any).content.map((content: any) =>
                mapResponseToOpsBatch(content, (data as any).totalElements)
              )
            : []
        ),
        flatMap(response => {
          const obs: any[] = [
            batchActions.fetchBatchesOnProcessing.success(response)
          ];
          return obs;
        }),
        catchError(err =>
          of(batchActions.fetchBatchesOnProcessing.failure(err))
        )
      );
    })
  );

export const fetchBatchesOnCompletedEpic = (
  action: Observable<batchActions.batchActionTypes>
) =>
  action.pipe(
    filter(isActionOf(batchActions.fetchBatchesOnCompleted.request)),
    switchMap(({ payload }) => {
      return opsService.listBatches(payload || undefined).pipe(
        map(data =>
          data
            ? (data as any).content.map((content: any) =>
                mapResponseToOpsBatch(content, (data as any).totalElements)
              )
            : []
        ),
        flatMap(response => {
          const obs: any[] = [
            batchActions.fetchBatchesOnCompleted.success(response)
          ];
          return obs;
        }),
        catchError(err => of(batchActions.fetchBatchesOnCompleted.failure(err)))
      );
    })
  );

export const fetchBatchEpic = (
  action: Observable<batchActions.batchActionTypes>
) =>
  action.pipe(
    filter(isActionOf(batchActions.fetchBatch.request)),
    switchMap(({ payload }) => {
      return opsService.getBatch(payload).pipe(
        map(mapResponseToOpsBatch),
        flatMap(batches => {
          return [
            batchActions.fetchBatch.success(batches),
            batchActions.fetchBatchHistory.request(payload),
            errorSummaryActions.fetchErrorSummary.request(payload)
          ];
        }),
        catchError(err => of(batchActions.fetchBatch.failure(err)))
      );
    })
  );

export function mapResponseToOpsBatchDetails(
  data: any,
  batchId: string
): OpsBatchDetails {
  return new OpsBatchDetails(
    data.cardNumber,
    data.errorCodes,
    data.issuerCode,
    data.lineNumber,
    data.locationCode,
    data.messageCodes,
    data.miles,
    data.offerCode,
    data.originalCardNumber,
    data.succeeded,
    data.transactionId,
    batchId
  );
}

export const fetchBatchDetailsEpic = (
  action: Observable<batchActions.batchActionTypes>
) =>
  action.pipe(
    filter(isActionOf(batchActions.fetchBatchDetails.request)),
    switchMap(({ payload }) => {
      return opsService.getBatchDetails(payload).pipe(
        map(data =>
          (data as any).content.map((d: any) =>
            mapResponseToOpsBatchDetails(d, payload)
          )
        ),
        map((data: any) => groupBy("batchId", data)),
        map(batchActions.fetchBatchDetails.success),
        catchError(err => of(batchActions.fetchBatchDetails.failure(err)))
      );
    })
  );

export function mapResponseToOpsBatchHistory(
  data: any,
  batchId: string
): OpsBatchHistory {
  return new OpsBatchHistory(
    data.activityType,
    data.date,
    data.stage,
    data.status,
    batchId
  );
}

export const fetchBatchHistoryEpic = (
  action: Observable<batchActions.batchActionTypes>
) =>
  action.pipe(
    filter(isActionOf(batchActions.fetchBatchHistory.request)),
    switchMap(({ payload }) => {
      return opsService.getBatchHistory(payload).pipe(
        map((data: any) =>
          data.map((d: any) => mapResponseToOpsBatchHistory(d, payload))
        ),
        map((data: any) => groupBy("batchId", data)),
        map(batchActions.fetchBatchHistory.success),
        catchError(err => of(batchActions.fetchBatchHistory.failure(err)))
      );
    })
  );

function BatchActionModalContent({ message }: { message: string }) {
  return (
    <Grid container={true} alignItems="center" justify="center">
      <Typography paragraph={true} variant="title">
        {message}
      </Typography>
    </Grid>
  );
}

function handleActionResponse(action: string, response: Response) {
  const modalProps = {
    action: action,
    message: () => (
      <BatchActionModalContent
        message={`File ${action} ${response.ok ? "success" : "error"}`}
      />
    ),
    type: response.ok ? BB8ModalTypes.Success : BB8ModalTypes.Error
  } as IModalProps;
  return communicationActions.displayModal(modalProps);
}

export const reprocessFileEpic = (
  action: Observable<batchActions.batchActionTypes>
) =>
  action.pipe(
    filter(isActionOf(batchActions.reprocessFile.request)),
    switchMap(({ payload }) => {
      return opsService.reprocessFile(payload).pipe(
        flatMap((result: Response) => [
          handleActionResponse("reprocess", result),
          batchActions.reprocessFile.success(),
          batchActions.fetchBatch.request(payload)
        ]),
        catchError(err => of(batchActions.reprocessFile.failure(err)))
      );
    })
  );

export const rejectFileEpic = (
  action: Observable<batchActions.batchActionTypes>
) =>
  action.pipe(
    filter(isActionOf(batchActions.rejectFile.request)),
    switchMap(({ payload }) => {
      return opsService.rejectFile(payload).pipe(
        flatMap((result: Response) => [
          handleActionResponse("reject", result),
          batchActions.reprocessFile.success(),
          batchActions.fetchBatch.request(payload)
        ]),

        catchError(err => of(batchActions.rejectFile.failure(err)))
      );
    })
  );

export const retryFileEpic = (
  action: Observable<batchActions.batchActionTypes>
) =>
  action.pipe(
    filter(isActionOf(batchActions.retryFile.request)),
    switchMap(({ payload }) => {
      return opsService.retryFile(payload).pipe(
        flatMap((result: Response) => [
          handleActionResponse("retry", result),
          batchActions.reprocessFile.success(),
          batchActions.fetchBatch.request(payload)
        ]),
        catchError(err => of(batchActions.retryFile.failure(err)))
      );
    })
  );

export const auditFileEpic = (
  action: Observable<batchActions.batchActionTypes>
) =>
  action.pipe(
    filter(isActionOf(batchActions.auditFile.request)),
    switchMap(({ payload }) => {
      return opsService.auditFile(payload).pipe(
        flatMap((result: Response) => [
          handleActionResponse("audit", result),
          batchActions.reprocessFile.success(),
          batchActions.fetchBatch.request(payload)
        ]),
        catchError(err => of(batchActions.auditFile.failure(err)))
      );
    })
  );

export const backedOutFileEpic = (
  action: Observable<batchActions.batchActionTypes>
) =>
  action.pipe(
    filter(isActionOf(batchActions.backedOutFile.request)),
    switchMap(({ payload }) => {
      return opsService.backedOutFile(payload).pipe(
        flatMap((result: Response) => [
          handleActionResponse("backed out", result),
          batchActions.reprocessFile.success(),
          batchActions.fetchBatch.request(payload)
        ]),
        catchError(err => of(batchActions.backedOutFile.failure(err)))
      );
    })
  );
