import {
    connectRouter,
    routerMiddleware
  } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import rootReducer, { rootEpic } from "./store";

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware();

const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;


  const configureStore = () => {


    const store = createStore(
      connectRouter(history)(rootReducer),
      {},
      composeEnhancers(applyMiddleware(routerMiddleware(history), epicMiddleware))
    );
    
    epicMiddleware.run(rootEpic);

  if (process.env.NODE_ENV !== 'production') {
    if ((module as any).hot) {
      (module as any).hot.accept('./store', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return store;
};

export default configureStore;