import { BB8Badge } from "bb8/lib/index";
import React from "react";
import { OpsBatchStatus } from "../models/OpsBatch";
export const SmartBadge = ({
  opsBatchStatus
}: {
  opsBatchStatus: OpsBatchStatus;
}) => (
  // <div>{opsBatchStatus}</div>
  <BB8Badge title={opsBatchStatus} color={colorConverter(opsBatchStatus)} />
);

export default SmartBadge;

function colorConverter(title: OpsBatchStatus) {
  switch (title) {
    case OpsBatchStatus.Completed: {
      return "bb8-green";
    }
    case OpsBatchStatus.OnHold: {
      return "bb8-orange";
    }
    case OpsBatchStatus.Rejected: {
      return "bb8-pink";
    }
    default: {
      return "bb8-blue";
    }
  }
}
