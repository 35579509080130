export function isEmpty(obj: object): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export function queryStringToObject<T>(qs: string): T | any | undefined {
  if (!qs) {
    return;
  }
  const kvs = qs
    .replace("?", "")
    .split("&")
    .map(kv => kv.split("="));

  const result: any = {};
  kvs.forEach(kv => {
    result[kv[0]] = kv[1];
  });
  return result;
}

export function objectToQueryString(obj: any): string | undefined {
  if (!obj) {
    return;
  }
  return Object.keys(obj)
    .map(
      k =>
        obj[k] !== undefined && obj[k] !== null && obj[k] !== ""
          ? `${k}=${obj[k]}`
          : ""
    )
    .filter(i => !!i)
    .join("&");
}

export function groupBy(
  key: string,
  array: any[],
  transform?: (obj: any) => any
): { [key: string]: any[] } {
  const result: any = {};

  if (array && array.length) {
    const id = array[0][key];
    if (transform) {
      result[id] = array.map(d => transform(d));
    } else {
      result[id] = array;
    }
  }

  return result;
}

export const groupByAttribute = (xs: any, key: any) => {
  return xs.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const diffArray = (arr1: any[], arr2: any[]) => {
  return arr1
    .filter(el => !arr2.includes(el))
    .concat(arr2.filter(el => !arr1.includes(el)));
};

export function updateObjectInArray<T>(
  array1: T[],
  obj: T,
  matcher: (item1: T, item2: T) => boolean
): T[] {
  return array1.map(item => {
    if (!matcher(item, obj)) {
      // This isn't the item we care about - keep it as-is
      return item;
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...(item as any),
      ...(obj as any)
    };
  });
}
export function countDistinctBy<T>(a: T[], key: string): number {
  if (!a || !a.length) {
    return 0;
  }
  let result = 0;
  const temp = new Set();
  for (const obj of a) {
    if (obj && (obj as any)[key]) {
      const val = (obj as any)[key];
      if (!temp.has(val)) {
        result += 1;
        temp.add(val);
      }
    }
  }
  return result;
}