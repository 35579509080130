import { from, Observable } from "rxjs";
import { flatMap, map } from "rxjs/operators";
import { urls } from "../constants/app-constants";
import { OpsBatch } from "../models";
import {
  IOpsBatchRequestParams,
  OpsBatchRequestParams
} from "../models/OpsBatchRequestParams";
import apiService from "./api.service";

function mapHttpReponse(response: Response): Observable<OpsBatch[] | Error> {
  if (!response.ok) {
    return from(
      response
        .json()
        .then(err => {
          throw err;
        })
        .catch(err => {
          throw err;
        })
    );
  } else {
    return from(response.json());
  }
}
export class OpsService {
  private apiService: any | GlobalFetch;
  constructor(apiServiceDep?: any) {
    this.apiService = apiServiceDep || apiService;
  }

  /**
   * Returns an Observable<Promise>.
   *
   * @remarks
   * This method is part of the {@link core-library#Statistics | Statistics subsystem}.
   *
   * @returns Observable<Promise>
   *
   * @beta
   */
  public listBatches(params?: IOpsBatchRequestParams) {
    let innerParams = params || new OpsBatchRequestParams();

    const qs = innerParams ? "?" + innerParams.toQueryString() : "";

    const request: Request = new Request(
      urls.restApi + urls.batchControllerForOps.batch + qs,
      this.apiService.getDefaultRequestInit()
    );
    return from<OpsBatch[] | Error>(this.apiService.ajax(request)).pipe(flatMap(
      mapHttpReponse
    ) as any);
  }

  public getBatch(batchId: string) {
    const request: Request = new Request(
      urls.restApi + urls.batchControllerForOps.getBatch(batchId),
      this.apiService.getDefaultRequestInit()
    );
    return from<OpsBatch | Error>(this.apiService.ajax(request)).pipe(flatMap<
      any,
      any
    >(mapHttpReponse) as any);
  }

  public getBatchDetails(batchId: string) {
    const request: Request = new Request(
      urls.restApi +
        urls.batchControllerForOps.batchDetails(batchId, "errorsOnly"),
      this.apiService.getDefaultRequestInit()
    );
    return from(this.apiService.ajax(request)).pipe(
      flatMap<any, any>(mapHttpReponse)
    );
  }
  public getBatchHistory(batchId: string) {
    const request: Request = new Request(
      urls.restApi + urls.batchControllerForOps.batchHistory(batchId),
      this.apiService.getDefaultRequestInit()
    );
    return from(this.apiService.ajax(request)).pipe(
      flatMap<any, any>(mapHttpReponse)
    );
  }
  public errorSummary(batchId: string) {
    const request: Request = new Request(
      urls.restApi + urls.batchControllerForOps.errorSummary(batchId),
      this.apiService.getDefaultRequestInit()
    );
    return from(this.apiService.ajax(request)).pipe(
      flatMap<any, any>(mapHttpReponse),
      map((responses: any) =>
        responses.map((json: any) => ({ ...json, batchId }))
      )
    );
  }
  public reprocessFile(batchId: string): any {
    const request: Request = new Request(
      urls.restApi + urls.batchControllerForOps.reprocessBatch(batchId),
      this.apiService.getDefaultRequestInit("POST")
    );
    return from(this.apiService.ajax(request));
  }
  public rejectFile(batchId: string): any {
    const request: Request = new Request(
      urls.restApi + urls.batchControllerForOps.rejectBatch(batchId),
      this.apiService.getDefaultRequestInit("POST")
    );
    return from(this.apiService.ajax(request));
  }
  public retryFile(batchId: string): any {
    const request: Request = new Request(
      urls.restApi + urls.batchControllerForOps.retryBatch(batchId),
      this.apiService.getDefaultRequestInit("POST")
    );
    return from(this.apiService.ajax(request));
  }
  public auditFile(batchId: string): any {
    const request: Request = new Request(
      urls.restApi + urls.batchControllerForOps.forceAudit(batchId),
      this.apiService.getDefaultRequestInit("POST")
    );
    return from(this.apiService.ajax(request));
  }
  public backedOutFile(batchId: string): any {
    const request: Request = new Request(
      urls.restApi + urls.batchControllerForOps.backedOutBatch(batchId),
      this.apiService.getDefaultRequestInit("POST")
    );
    return from(this.apiService.ajax(request));
  }
}

const defaultOpsService = new OpsService();

export default defaultOpsService;
