import { ActionType, createStandardAction, getType } from "typesafe-actions";
import { IModalProps } from "../../../models/Modal";

export const displayModal = createStandardAction("COMM/DISPLAY_MODAL")<
  IModalProps
>();

export const dismissModal = createStandardAction("COMM/DISMISS_MODAL")();

export const clearErrors = createStandardAction("COMM/CLEAR_ERRORS")();

export const displayModalActionType = getType(displayModal);
export const dismissModalActionType = getType(dismissModal);
export const clearErrorsActionType = getType(clearErrors);

export type communicationActionTypes =
  | ActionType<typeof displayModal>
  | ActionType<typeof dismissModal>
  | ActionType<typeof clearErrors>;
