import { PrivateRoute } from "bb8/lib/index";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as loginActions from "../features/auth/actions";
import authService from "../services/auth.service";
import { IRootState } from "../store/index";

export const ConnectedPrivateRoute = connect<any, any, any, any>(
  (state: IRootState) => ({
    isLoggedIn: authService.isLoggedIn,
    user: state.auth.user
  }),
  (dispatch: Dispatch) => ({
    login: () => dispatch(loginActions.fetchLogin.request(false)),
    logout: () => dispatch(loginActions.fetchLogout.request())
  })
)(PrivateRoute);

export default ConnectedPrivateRoute;
