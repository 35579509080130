import moment from "moment";
import { objectToQueryString, queryStringToObject } from "../helpers";
import { OpsBatchStage, OpsBatchStatus } from "./OpsBatch";
export interface IOpsBatchRequestParams {
  providerCode?: string;
  sequenceNumber?: number;
  stages?: string[] | OpsBatchStage[];
  statuses?: string[] | OpsBatchStatus[];
  minFileObservedDate?: moment.Moment;
  maxFileObservedDate?: moment.Moment;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  sortField?:
    | "Provider"
    | "SequenceNumber"
    | "Status"
    | "Stage"
    | "FileObservedDate";
  sortDirection?: "Asc" | "Desc";
  page?: number;
  size?: number;

  loadErrors?: boolean;
  toQueryString: () => string | undefined;

  date?: "today" | "yesterday" | "last-days-7" | "custom";
}

export class OpsBatchRequestParams implements IOpsBatchRequestParams {
  public static fromQueryString(qs: string): IOpsBatchRequestParams {
    if (!qs) {
      return new OpsBatchRequestParams();
    }
    const params = queryStringToObject(qs);
    return new OpsBatchRequestParams(params);
  }
  public providerCode: string | undefined;
  public sequenceNumber: number | undefined;
  public stages: string[] | undefined;
  public statuses: string[] | undefined;
  public minFileObservedDate: moment.Moment | undefined;
  public maxFileObservedDate: moment.Moment | undefined;
  public minDate: moment.Moment | undefined;
  public maxDate: moment.Moment | undefined;
  public sortField:
    | "Provider"
    | "SequenceNumber"
    | "Status"
    | "Stage"
    | "FileObservedDate"
    | undefined;
  public sortDirection: "Asc" | "Desc" | undefined;
  public page: number | undefined;
  public size: number | undefined;
  public loadErrors?: boolean;
  public date: "today" | "yesterday" | "last-days-7" | "custom" | undefined;
  constructor(params?: any) {
    if (params) {
      this.providerCode = params.providerCode;
      this.sequenceNumber = params.sequenceNumber;
      this.stages = params.stages;
      this.statuses = params.statuses;
      if (params.minFileObservedDate) {
        this.minFileObservedDate = moment(params.minFileObservedDate);
      }
      if (params.minDate) {
        this.minDate = moment(params.minDate);
      }
      if (params.maxDate) {
        this.maxDate = moment(params.maxDate);
      }
      if (params.maxFileObservedDate) {
        this.maxFileObservedDate = moment(params.maxFileObservedDate);
      }
      this.sortField = params.sortField;
      this.sortDirection = params.sortDirection;
      this.page = params.page;
      this.size = params.size;
      this.date = params.date;

      switch (params.date) {
        case "today": {
          if (
            this.statuses &&
            this.statuses.indexOf(OpsBatchStatus.Completed) !== -1 &&
            this.statuses.indexOf(OpsBatchStatus.OnHold) === -1 &&
            this.statuses.indexOf(OpsBatchStatus.Processing) === -1 &&
            this.statuses.indexOf(OpsBatchStatus.Rejected) === -1
          ) {
            this.minDate = moment().startOf("day");
            this.minFileObservedDate = undefined;
          } else {
            this.minFileObservedDate = moment().startOf("day");
          }
          break;
        }
        case "yesterday": {
          this.minFileObservedDate = moment()
            .subtract(1, "d")
            .startOf("day");
          this.maxFileObservedDate = moment()
            .subtract(1, "d")
            .endOf("day");
          break;
        }
        case "last-days-7": {
          if (
            this.statuses &&
            this.statuses.length === 1 &&
            this.statuses.indexOf(OpsBatchStatus.Completed) !== -1
          ) {
            this.minDate = moment()
              .subtract(7, "d")
              .startOf("day");
          } else {
            this.minFileObservedDate = moment()
              .subtract(7, "d")
              .startOf("day");
          }
          break;
        }
      }
    }
  }

  public toQueryString() {
    const { minFileObservedDate, maxFileObservedDate, minDate, maxDate } = this;
    const minDateObservedStr = minFileObservedDate
      ? (minFileObservedDate as moment.Moment).toISOString
        ? (minFileObservedDate as moment.Moment).toISOString(true)
        : minFileObservedDate.toString()
      : undefined;
    const minDateStr = minDate
      ? (minDate as moment.Moment).toISOString
        ? (minDate as moment.Moment).toISOString(true)
        : minDate.toString()
      : undefined;
    const maxDateStr = maxDate
      ? (maxDate as moment.Moment).toISOString
        ? (maxDate as moment.Moment).toISOString(true)
        : maxDate.toString()
      : undefined;
    const maxDateObservedStr = maxFileObservedDate
      ? (maxFileObservedDate as moment.Moment).toISOString
        ? (maxFileObservedDate as moment.Moment).toISOString(true)
        : maxFileObservedDate.toString()
      : undefined;

    return objectToQueryString({
      date: this.date,
      maxDate: maxDateStr,
      maxFileObservedDate: maxDateObservedStr,
      minDate: minDateStr,
      minFileObservedDate: minDateObservedStr,
      page: this.page,
      providerCode: this.providerCode,
      sequenceNumber: this.sequenceNumber,
      size: this.size,
      sortDirection: this.sortDirection,
      sortField: this.sortField,
      stages: this.stages,
      statuses: this.statuses
    });
  }
}
