export class OpsBatchDetails {
  public cardNumber: string;
  public errorCodes: string[];
  public issuerCode: string;
  public lineNumber: number;
  public locationCode: string;
  public messageCodes: string[];
  public miles: number;
  public offerCode: string;
  public originalCardNumber: string;
  public succeeded: boolean;
  public transactionId: string;
  public batchId: string;
  constructor(
    cardNumber: string,
    errorCodes: string[],
    issuerCode: string,
    lineNumber: number,
    locationCode: string,
    messageCodes: string[],
    miles: number,
    offerCode: string,
    originalCardNumber: string,
    succeeded: boolean,
    transactionId: string,
    batchId: string,
  ) {
    this.cardNumber = cardNumber;
    this.errorCodes = errorCodes;
    this.issuerCode = issuerCode;
    this.lineNumber = lineNumber;
    this.locationCode = locationCode;
    this.messageCodes = messageCodes;
    this.miles = miles;
    this.offerCode = offerCode;
    this.originalCardNumber = originalCardNumber;
    this.succeeded = succeeded;
    this.transactionId = transactionId;
    this.batchId = batchId;
  }
}
