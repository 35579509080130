import { Moment } from "moment";
import { OpsBatchDetails } from "./OpsBatchDetails";
import { OpsBatchErrorSummary } from "./OpsBatchErrorSummary";
import { OpsBatchHistory } from "./OpsBatchHistory";

export class OpsBatch {
  public acceptedWithoutWarningsLines: number;
  public acceptedWithoutWarningsMiles: number;
  public batchId: string;
  public lastActivityDate: string | Date | Moment | undefined;
  public providerCode: string;
  public rejectedLines: number;
  public rejectedMiles: number;
  public sequenceNumber: string;
  public stage: OpsBatchStage;
  public status: OpsBatchStatus;
  public errorSummaries?: OpsBatchErrorSummary[];
  public details?: OpsBatchDetails[];
  public history?: OpsBatchHistory[];
  public fileCreated?: string;
  public lastActivityUsername: string;
  public acceptedWithWarningsLines: number;
  public acceptedWithWarningsMiles: number;
  public userName: string;
  public totalAcceptedLines: number;
  public totalAcceptedMiles: number;
  public totalLines: number;
  public totalMiles: number;
  public totalElementsAvailable: number;
  constructor(
    acceptedWithoutWarningsLines: number,
    acceptedWithoutWarningsMiles: number,
    batchId: string,
    lastActivityDate: string | Date | Moment | undefined,
    provider: string,
    rejectedLines: number,
    rejectedMiles: number,
    sequenceNumber: string,
    stage: OpsBatchStage,
    status: OpsBatchStatus,
    lastActivityUsername: string,
    acceptedWithWarningsLines: number,
    acceptedWithWarningsMiles: number,
    userName: string,
    totalAcceptedLines: number,
    totalAcceptedMiles: number,
    totalLines: number,
    totalMiles: number,
    totalElementsAvailable: number
  ) {
    this.acceptedWithoutWarningsLines = acceptedWithoutWarningsLines;
    this.acceptedWithoutWarningsMiles = acceptedWithoutWarningsMiles;
    this.batchId = batchId;
    this.lastActivityDate = lastActivityDate;
    this.providerCode = provider;
    this.rejectedLines = rejectedLines;
    this.rejectedMiles = rejectedMiles;
    this.sequenceNumber = sequenceNumber;
    this.stage = stage;
    this.status = status;
    this.lastActivityUsername = lastActivityUsername;
    this.acceptedWithWarningsLines = acceptedWithWarningsLines;
    this.acceptedWithWarningsMiles = acceptedWithWarningsMiles;
    this.userName = userName;
    this.totalAcceptedLines = totalAcceptedLines;
    this.totalAcceptedMiles = totalAcceptedMiles;
    this.totalLines = totalLines;
    this.totalMiles = totalMiles;
    this.totalElementsAvailable = totalElementsAvailable;
  }
}

export enum OpsBatchStatus {
  Rejected = "Rejected",
  OnHold = "Hold",
  Completed = "Complete",
  Processing = "InProgress",
  All = "All"
}

export enum OpsBatchStage {
  BatchBackOut = "BatchBackOut",
  HeaderValidation = "HeaderValidation",
  DetailProcessing = "DetailProcessing",
  AuditGeneration = "AuditGeneration",
  All = "All"
}
