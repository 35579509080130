import { ICommunicationState } from "../../../models/Communication";
import { IModalProps, BB8ModalTypes } from "../../../models/Modal";
import { batchActions, errorSummaryActions } from "../../batch/actions";
import * as communicationActions from "../actions";
import React from "react";
export const initialState: ICommunicationState = {
  pendingRequests: 0,
  showModal: undefined
};

const communicationReducer = (
  state: ICommunicationState = initialState,
  action:
    | batchActions.batchActionTypes
    | errorSummaryActions.errorSummaryActionTypes
    | communicationActions.communicationActionTypes
): ICommunicationState => {
  switch (action.type) {
    case batchActions.fetchBatchRequest:
    case batchActions.fetchBatchesRequest:
    case batchActions.fetchBatchDetailsRequest:
    case batchActions.fetchBatchHistoryRequest:
    case batchActions.auditFileRequest:
    case batchActions.backedOutFileRequest:
    case batchActions.rejectFileRequest:
    case batchActions.retryFileRequest:
    case batchActions.reprocessFileRequest:
    case errorSummaryActions.fetchErrorSummaryRequest:
    case errorSummaryActions.fetchErrorSummariesRequest: {
      return { ...state, pendingRequests: state.pendingRequests + 1 };
    }
    case batchActions.fetchBatchSuccess:
    case batchActions.fetchBatchesSuccess:
    case batchActions.fetchBatchDetailsSuccess:
    case batchActions.fetchBatchHistorySuccess:
    case errorSummaryActions.fetchErrorSummarySuccess:
    case errorSummaryActions.fetchErrorSummariesSuccess: {
      return {
        ...state,
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
      };
    }
    case batchActions.auditFileSuccess:
    case batchActions.backedOutFileSuccess:
    case batchActions.retryFileSuccess:
    case batchActions.rejectFileSuccess:
    case batchActions.reprocessFileSuccess: {
      return {
        ...state,
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
        // showModal: {
        //   action: action.payload.action || "success",
        //   message: () =>
        //     React.createElement("div", {}, [
        //       action.payload.message || action.payload.toString()
        //     ]),
        //   type: action.payload.type || BB8ModalTypes.Success
        // } as IModalProps
      };
    }
    case batchActions.auditFileFailure:
    case batchActions.backedOutFileFailure:
    case batchActions.retryFileFailure:
    case batchActions.rejectFileFailure:
    case batchActions.reprocessFileFailure: {
      return {
        ...state,
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
        // showModal: {
        //   action: "error",
        //   message: () => React.createElement("div", {}, action.payload.message),
        //   type: BB8ModalTypes.Error
        // } as IModalProps
      };
    }
    case batchActions.fetchBatchFailure:
    case batchActions.fetchBatchesFailure:
    case batchActions.fetchBatchDetailsFailure:
    case batchActions.fetchBatchHistoryFailure:
    case errorSummaryActions.fetchErrorSummaryFailure:
    case errorSummaryActions.fetchErrorSummariesFailure: {
      return {
        ...state,
        error: action.payload,
        pendingRequests: Math.max(state.pendingRequests - 1, 0)
      };
    }
    case batchActions.clearErrorsActionType:
    case communicationActions.clearErrorsActionType: {
      return { ...state, error: undefined };
    }
    case batchActions.dismissModalActionType:
    case communicationActions.dismissModalActionType: {
      return {
        ...state,
        showModal: undefined
      };
    }
    case communicationActions.displayModalActionType: {
      return { ...state, showModal: action.payload as IModalProps };
    }
    default:
      return state;
  }
};

export default communicationReducer;
